<template>
  <div class="getCouBox">
    <div class="getCou"></div>
    <div class="coupon_top">
      <div class="coupon_cen">
        <div class="coupon_cen_one" v-if="list_list.super_count>0">
          <div class="coupon_cen_one_img">
            <img src="../../assets/coupon/right.png" class="image1" alt />
            <img src="../../assets/coupon/cou.png" class="image2" alt />
            <img src="../../assets/coupon/left.png" class="image3" alt />
          </div>
          <div class="coupon_cen_one_list">
            <div
              class="coupon_cen_one_list1"
              v-for="(item,index) in list_list.super_list"
              :key="index"
            >
              <div class="list1_money">
                <img src="../../assets/coupon/money.png" alt />
                <text style="font-size: 50rpx;">{{item.consumer_reduce_money}}</text>
              </div>
              <div class="list1_title">满{{item.consumer_full_money}}可用</div>
              <div class="list1_text" v-if="item.is_take==1" @click="getTake(item.id)">立即领取</div>
              <div class="list1_text1" v-else>立即领取</div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="coupon_cen_twoTwo" v-if="list_list.speed_count>0">
          <div class="twoTwo_one" v-for="(item,index) in list_list.speed_list" :key="index">
            <div class="twoTwo_one_left">
              <!-- <div class="twoTwo_one_left_num">5折</div> -->
              <div class="twoTwo_one_left_num">
                {{item.consumer_reduce_money}}
                <text>￥</text>
              </div>
              <div class="twoTwo_one_left_text">限{{item.merchant_name}}使用</div>
            </div>
            <div class="twoTwo_one_right" v-if="item.is_take == 1" @click="getTake(item.id)">
              <img src="../../assets/coupon/btn.png" alt />
            </div>
            <div class="twoTwo_one_right_over" v-else>
              <div class="list_right_go">点击领取</div>
            </div>
          </div>
        </div>
        <!--  -->
        <div
          class="coupon_cen_two"
          v-for="(item,index) in list_list.time_list"
          :key="index"
          v-if="list_list.time_count>0"
        >
          <div class="coupon_cen_two_list_left">
            <div class="list_left_left">{{item.consumer_reduce_money}}</div>
            <div class="list_left_right">
              <div class="list_left_right_num">满{{item.consumer_full_money}}立减</div>
              <div class="list_left_right_text">限{{item.limit_began_date}}起抢券</div>
            </div>
          </div>
          <div class="coupon_cen_two_list_right">
            <div
              class="list_right_go_content"
              v-if="item.is_take == 1"
              @click="getTake(item.id)"
            >点击领取</div>
            <div class="list_right_go" v-else>点击领取</div>
            <!-- <div class="list_right_go" >已结束</div> -->
            <!-- <div class="list_right_go_content">领取</div> -->
            <!-- <div class="list_right_go">已领完</div> -->
          </div>
        </div>
        <!--  -->
        <van-list
          v-model="isUpLoading"
          :finished="upFinished"
          :immediate-check="false"
          :offset="10"
          finished-text="我是有底线的"
          @load="onLoadVal"
        >
          <div class="coupon_cen_three">
            <div class="coupon_cen_three_img">
              <img src="../../assets/coupon/left.png" alt class="image3" />
              <div class="name">优惠好券</div>
              <img src="../../assets/coupon/right.png" alt class="image1" />
            </div>
            <div class="coupon_cen_three_list" v-for="(item,index) in all_list" :key="index">
              <div class="coupon_cen_three_list_left">
                <div class="list_top">
                  <div class="list_left_left">
                    {{item.consumer_reduce_money}}
                    <!-- <100></100> -->
                  </div>
                  <div class="list_left_right">
                    <div class="list_left_right_num">满{{item.consumer_full_money}}立减</div>
                    <div class="list_left_right_text">限{{item.merchant_name}}使用</div>
                  </div>
                </div>
                <!-- 	<div class="list_time">
								限{{item.use_began_date|activeTime}}-{{item.use_end_date|activeTime}}使用
                </div>-->
              </div>
              <div class="coupon_cen_three_list_right">
                <div class="list_right_go" v-if="item.is_take == 1" @click="getTake(item.id)">点击领取</div>
                <div class="list_right_go_over" v-else>点击领取</div>
                <div class="start-time">{{item.limit_began_date}}起</div>
                <div class="end-time">{{item.limit_end_date}}止</div>
              </div>
            </div>
            <div class v-if="all_list.length==0">
              <div class="lookMore">暂无优惠券</div>
            </div>
          </div>
        </van-list>

        <!--  -->
      </div>
    </div>
  </div>
</template>
<script>
import { couponLst, couponTake } from "../../api/coupon";
import { Toast } from "vant";
export default {
  data() {
    return {
      nullTip: "空空如也~",
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      page: 1,
      limit: 10,
      type: "",
      list_list: "",
      super_count: 0,
      speed_count: 0,
      time_count: 0,
      all_list: [],
      error: false,
    };
  },
  mounted() {
    console.log(this.$route.query.type);
    // if (this.$route.query.type) {
    //   this.type = this.$route.query.type;
    // } else {
    this.type = "all";
    // }
    this.page = 1;
    this.couponLst();
  },
  methods: {
    getTake(id) {
      // console.log(id);
      let param = {
        id: id,
      };
      couponTake(param).then((res) => {
        // console.log(res);
        Toast(res.msg);
        this.couponLst();
      });
    },
    couponLst() {
      // console.log(this.page);
      let params = {
        page: this.page,
        limit: this.limit,
        type: this.type,
      };
      couponLst(params).then((res) => {
        if (this.page < 2) {
          this.list_list = res.data;
          this.all_list = res.data.list;
          // console.log(this.time_list);
        } else {
          // console.log(res);
          const rows = res.data.list;
          // if (rows.length > 0 && rows.length == this.limit) {
          //   // console.log(11111);
          // }
          if (rows.length === 0) {
            // 加载结束
            this.upFinished = true;
            return;
          }
          if (rows.length < this.limit) {
            // 最后一页不足10条的情况
            this.upFinished = true;
          }
          // 处理数据
          if (this.page < 2) {
            this.all_list = rows;
          } else {
            this.all_list = this.all_list.concat(rows);
          }
        }

        // console.log(this.goodsList)
        this.$toast.clear();
      });
    },
    // 上拉加载请求方法
    onLoadVal() {
      // console.log(this.upFinished);
      // console.log("-------" + this.page);
      if (this.error == true) {
        this.page++;
      }
      this.couponLst();
    },
  },
};
</script>
<style scoped lang="scss">
@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}
.getCouBox {
  background: #ba1a14;

  .getCou {
    background-image: url(../../assets/coupon/bj.png);
    background-size: 100% auto;
    min-height: 100vh;
    overflow: hidden;
  }
  .coupon_top {
    background-image: url(../../assets/coupon/crile.png);
    background-size: 100% auto;
    margin-top: -385px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .coupon_cen {
    width: 100%;
    padding-top: 160px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    .coupon_cen_one {
      height: 180px;
      background: linear-gradient(
        159deg,
        #fee2c0 0%,
        #ffd4a0 51%,
        #f6996b 100%
      );
      border-radius: 10px;
      padding: 1px;
      .coupon_cen_one_img {
        display: flex;
        justify-content: space-around;
        margin: 15px 0;
        .image1 {
          width: 91px;
          height: 11px;
        }

        .image2 {
          width: 86px;
          height: 17px;
        }

        .image3 {
          width: 91px;
          height: 11px;
        }
      }
      //
      .coupon_cen_one_list {
        display: flex;
        justify-content: space-around;
        margin: 17px 5px;
        .coupon_cen_one_list1 {
          width: 78px;
          height: 110px;
          background: linear-gradient(180deg, #fc673a 0%, #de2d1c 100%);
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .list1_money {
            width: 100%;
            text-align: center;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            position: relative;

            img {
              width: 15px;
              height: 15px;
              position: absolute;
              top: 0;
              left: 5px;
            }
          }
          .list1_title {
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            margin: 8px 0;
            transform: scale(calc(10 / 12));
          }
          .list1_text {
            width: 58px;
            height: 18px;
            background: linear-gradient(180deg, #fdd7ab 0%, #ffca99 100%);
            border-radius: 9px;
            line-height: 19px;
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ba1a14;
            text-align: center;
          }

          .list1_text1 {
            width: 58px;
            height: 19px;
            background-color: #cecece;
            border-radius: 9px;
            line-height: 18px;
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333;
            text-align: center;
          }
        }
      }
      //
    }
    //
    .coupon_cen_twoTwo {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .twoTwo_one {
        width: 162px;
        height: 78px;
        background: linear-gradient(159deg, #fee2c0 0%, #ffd4a0 88%);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .list_right_go {
          width: 75px;
          height: 22px;
          line-height: 22px;
          background: #cecece;
          border-radius: 12px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          text-align: center;
        }
        .twoTwo_one_left {
          .twoTwo_one_left_num {
            font-size: 31px;
            font-family: HarmonyOS_Sans_SC_Black;
            font-weight: 400;
            font-style: italic;
            color: #df2f1d;

            text {
              padding: 0 2px;
              border: solid 1px #df2f1d;
              font-size: 10px;
              border-radius: 50%;
              margin-left: 8px;
              font-style: normal;
            }
          }
          .twoTwo_one_left_text {
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #df2f1d;
          }
        }
        .twoTwo_one_right {
          width: 55px;
          //   height: 55px;
          animation: zoom 0.5s infinite;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .twoTwo_one_right_over {
          width: 55px;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    //
    .coupon_cen_two {
      background-image: url(../../assets/coupon/yellowRect.png);
      background-size: 100% auto;
      height: 69px;
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      .coupon_cen_two_list_left {
        display: flex;
        align-items: center;
        height: 100%;
        .list_left_left {
          width: 75px;
          font-size: 20px;
          font-family: HarmonyOS_Sans_SC_Black;
          font-weight: bold;
          font-style: italic;
          color: #df2f1d;
          text-align: center;
        }
        .list_left_right {
          margin-left: 10px;
          .list_left_right_num {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #df2f1d;
          }

          .list_left_right_text {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #df2f1d;
            margin-top: 8px;
            // transform: scale(calc(10 / 12));
          }
        }
      }
      .coupon_cen_two_list_right {
        .list_right_go {
          width: 75px;
          height: 23px;
          line-height: 23px;
          background: #cecece;
          border-radius: 12px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          text-align: center;
        }
        .list_right_go_content {
          width: 65px;
          height: 22px;
          font-size: 12px;
          font-weight: bold;
          color: #ba1a14;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .list_right_text {
          font-size: 11px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-top: 9px;
        }
      }
    }
    //
    .coupon_cen_three {
      //   width: 100%;
      //   height: 300px;
      background: linear-gradient(
        159deg,
        #fee2c0 0%,
        #ffd4a0 51%,
        #f6996b 100%
      );
      border-radius: 10px;
      padding: 15px;
      .coupon_cen_three_img {
        display: flex;
        justify-content: space-around;
        padding-bottom: 15px;
        align-items: center;

        .name {
          font-size: 17px;
          font-weight: 900;
          font-style: italic;
          color: #b5100c;
          letter-spacing: 2px;
        }

        .image1 {
          width: 91px;
          height: 11px;
        }

        .image2 {
          width: 88px;
          height: 17px;
        }

        .image3 {
          width: 91px;
          height: 11px;
        }
      }
      .coupon_cen_three_list {
        width: 100%;
        height: 70px;
        background-image: url(../../assets/coupon/rectangle.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 10px;
        display: flex;
        .coupon_cen_three_list_left {
          width: 200px;
          padding: 10px;

          .list_top {
            display: flex;
            align-items: center;

            .list_left_left {
              text-align: center;
              width: 74px;
              font-size: 30px;
              font-family: HarmonyOS_Sans_SC_Black;
              font-weight: bold;
              font-style: italic;
              color: #ffffff;
            }

            .list_left_right {
              flex: 1;
              margin-left: 15px;

              .list_left_right_num {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
              }

              .list_left_right_text {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-top: 8px;
              }
            }
          }

          .list_time {
            text-align: center;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .coupon_cen_three_list_right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          padding-bottom: 5px;

          .list_right_go {
            width: 75px;
            height: 25px;
            line-height: 25px;
            background: linear-gradient(180deg, #fdd7ab 0%, #ffca99 100%);
            border-radius: 13px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #df2e1c;
            text-align: center;
            margin-bottom: 6px;
          }

          .list_right_go_over {
            width: 75px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ccc;
            border-radius: 14px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333;
            text-align: center;
            margin-bottom: 5px;
          }

          .start-time,
          .end-time {
            // margin-top: 6rpx;
            text-align: center;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .lookMore {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ba1a14;
        line-height: 100px;
        text-align: center;
      }
    }
    //
  }
}
</style>